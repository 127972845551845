import dr1 from "./assets/Diana Rider/1.jpeg";
import dr2 from "./assets/Diana Rider/2.jpeg";
import dr3 from "./assets/Diana Rider/3.jpeg";
import dr4 from "./assets/Diana Rider/4.jpeg";

import sp1 from "./assets/Sofia Parker/1.jpeg";
import sp2 from "./assets/Sofia Parker/2.jpeg";
import sp3 from "./assets/Sofia Parker/3.jpeg";

import ad1 from "./assets/Anastasia Doll/1.jpeg";
import ad2 from "./assets/Anastasia Doll/2.jpeg";
import ad3 from "./assets/Anastasia Doll/3.jpeg";

import mm1 from "./assets/Mona Maria/1.jpeg";
import mm2 from "./assets/Mona Maria/2.jpeg";
import mm3 from "./assets/Mona Maria/3.jpeg";

import hz1 from "./assets/Hanna Zimmer/1.jpeg";
import hz2 from "./assets/Hanna Zimmer/2.jpeg";
import hz3 from "./assets/Hanna Zimmer/3.jpeg";
import hz4 from "./assets/Hanna Zimmer/4.jpeg";

import cs1 from "./assets/Candy Smile/1.jpeg";
import cs2 from "./assets/Candy Smile/2.jpeg";
import cs3 from "./assets/Candy Smile/3.jpeg";

import ya1 from "./assets/Your Angel/1.jpeg";
import ya2 from "./assets/Your Angel/2.jpeg";
import ya3 from "./assets/Your Angel/3.jpeg";

import o1 from "./assets/Octokuro/1.jpeg";
import o2 from "./assets/Octokuro/2.jpeg";
import o3 from "./assets/Octokuro/3.jpeg";

import sf1 from "./assets/Sweetie Fox/1.jpeg";
import sf2 from "./assets/Sweetie Fox/2.jpeg";
import sf3 from "./assets/Sweetie Fox/3.jpeg";

export default [
  {
    name: "Diana Rider",
    text: "Don’t be shy! Come chat with me 😘 I am here to make your day happier and something else harder 🤤😈",
    link: "https://onlyfans.com/action/trial/i2b12lv3fu8jnsg9ht1heeaoqbdwxn0z",
    images: [dr1, dr2, dr3, dr4],
  },
  {
    name: "Sofia Parker",
    text: "I'm Sofia, your sporty muse and fitness trainer who knows how to mix health with pleasure 💪 Love to treat myself to some sweet sins 🍰",
    link: "https://onlyfans.com/sofiaaaparker/c253",
    images: [sp1, sp2, sp3],
  },
  {
    name: "Anastasia Doll",
    text: "Finally a place I can be myself. ❤️😛 Welcome to the world of big boobs 😏 🔥👀💋",
    link: "https://onlyfans.com/anastasiadollofficial/c53",
    images: [ad1, ad2, ad3],
  },
  {
    name: "🎨 Mona Maria",
    text: "I am an artist, I paint a lot and often try to realize my imagination on a blank canvas 👩‍🎨 Now my creativity has turned into too explicit and I think it's a sign to open up to you 🎨",
    link: "https://onlyfans.com/monamariaa/c87",
    images: [mm1, mm2, mm3],
  },
  {
    name: "Hanna Zimmer",
    text: "Hi honey! I am Hanna, a naughty Geography teacher, who likes driving to school without driving license and panties 🙈",
    link: "https://onlyfans.com/action/trial/ur6anh4brqtxj20ezk7dtz51woazlwer",
    images: [hz1, hz2, hz3, hz4],
  },
  {
    name: "Candy Smile",
    text: " like to share my life here, I think you've already noticed my posts about my hobbies and activities, but I think you want to know what this candy smile is capable of in private messages 👿",
    link: "https://onlyfans.com/smile2003/c325",
    images: [cs1, cs2, cs3],
  },
  {
    name: "Your Angel",
    text: "I am very kind and naive, these qualities of mine cause a lot of problems in my life and here I want to find a kind person like me 🥺 I still hope that there are people who like to do good and can be sincere 🥰",
    link: "https://onlyfans.com/yourangel07/c127",
    images: [ya1, ya2, ya3],
  },
  {
    name: "Octokuro ⭐️",
    text: "Hey there! I'm Octokuro — naughty girl who loves xxx cosplay 😏",
    link: "https://onlyfans.com/action/trial/xdi2igl5l7tuctxybc70r5olmjg35lef",
    images: [o1, o2, o3],
  },
  {
    name: "Sweetie Fox ⭐️",
    text: "Your favorite cute cosplayer 🥰 Can't wait to show you my hot content to make you cum 💦😏",
    link: "https://onlyfans.com/sweetiefox_of/c165",
    images: [sf1, sf2, sf3],
  },
];
