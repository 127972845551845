import React, { useEffect, useRef } from "react";

import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import cn from "classnames";

import { FaXmark } from "react-icons/fa6";
import { FaHeart } from "react-icons/fa6";
import { FaMessage } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";

import { Link, useHistory } from "react-router-dom";

const scrollLenth = 80;

function Card({ name, text, link, images, next, urlName }) {
  const startTouch = useRef({});
  const history = useHistory();

  const openCard = () => {
    const el = document.getElementsByClassName("scroll")[0];
    el.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const closeCard = () => {
    const el = document.getElementsByClassName("wrapper")[0];
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const onSwipeStart = (e) => {
    startTouch.current = {
      clientY: e.touches[0].clientY,
      pageYOffset: window.pageYOffset,
    };
  };
  const onSwipeEnd = (e) => {
    startTouch.current = {};
  };

  const onSwipeMove = (e) => {
    const delta = startTouch.current.clientY - e.touches[0].clientY;
    if (delta > scrollLenth) {
      openCard();
    }
    if (delta < -scrollLenth) {
      closeCard();
    }
  };

  useEffect(() => {
    // const isAnim = localStorage.getItem("anim");
    const isAnim = window.isAnim;
    if (!isAnim) {
      const el = document.getElementsByClassName("slider")[0];
      el.style["transition-duration"] = "350ms";
      el.style["transition-timing-function"] = "ease-in-out";
      setTimeout(() => {
        el.style.transform = "translate3d(-110%, 0px, 0px)";
      }, 500);
      setTimeout(() => {
        el.style.transform = "translate3d(-100%, 0px, 0px)";
      }, 900);
    }
    window.isAnim = true;
    // localStorage.setItem("anim", true);
  }, []);

  return (
    <div className="wrapper">
      <div className="main">
        <Carousel
          onSwipeStart={onSwipeStart}
          onSwipeEnd={onSwipeEnd}
          onSwipeMove={onSwipeMove}
          swipeScrollTolerance={scrollLenth}
          className="carousel"
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          infiniteLoop
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            return (
              <li
                className="pin-wrapper"
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              >
                <div
                  className={cn({
                    pin: true,
                    "pin-selected": isSelected,
                  })}
                />
              </li>
            );
          }}
        >
          {images.map((src, i) => (
            <img
              className="image"
              src={src}
              alt={`Photo ${i} of ${name}`}
              key={i}
            />
          ))}
        </Carousel>

        <span className="onlylike">
          only<span>like</span>
        </span>

        <button onClick={history.goBack} className="back">
          <FaArrowLeftLong />
        </button>

        <div className="controls">
          <Link to={next} className="dismiss" onClick={closeCard}>
            <FaXmark />
          </Link>
          <button className="like" onClick={openCard}>
            <FaHeart />
          </button>
        </div>

        <div className="scroll">
          <p className="text">
            <span className="title">{name}</span>
            {text}
          </p>
          <div className="buttons">
            <Link to={next} className="next" onClick={closeCard}>
              <FaArrowRight />
            </Link>
            <button
              className="message"
              onClick={(e) => {
                e.preventDefault();

                if (typeof window.idsync !== "undefined") {
                  window.idsync.send_event({
                    name: urlName,
                    type: "Acquisition",
                    value: "0.100",
                  });
                }

                window.open(link, "_blank");
              }}
            >
              <FaMessage />
            </button>
          </div>
          <footer className="footer">
            <a href="./privacy-policy">Privacy Policy</a>
            <span>© 2024 OnlyLike</span>
          </footer>
        </div>
        <img
          id="1000481301_cpa_testing"
          src={`https://ads.trafficjunky.net/ct?a=1000481301&member_id=1004251891&cb=${new Date().getTime()}&cti=${
            window.localStorage.getItem("analytics_uuidv4") || "noUUID"
          }&ctd=${urlName}`}
          width="1"
          height="1"
          border="0"
        />
      </div>
    </div>
  );
}

export default Card;
